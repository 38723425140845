// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-js": () => import("../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("../src/pages/About/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-brandbook-index-js": () => import("../src/pages/Brandbook/index.js" /* webpackChunkName: "component---src-pages-brandbook-index-js" */),
  "component---src-pages-cnpj-index-js": () => import("../src/pages/Cnpj/index.js" /* webpackChunkName: "component---src-pages-cnpj-index-js" */),
  "component---src-pages-complain-here-index-js": () => import("../src/pages/ComplainHere/index.js" /* webpackChunkName: "component---src-pages-complain-here-index-js" */),
  "component---src-pages-contact-index-js": () => import("../src/pages/Contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-home-index-js": () => import("../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-how-it-works-index-js": () => import("../src/pages/HowItWorks/index.js" /* webpackChunkName: "component---src-pages-how-it-works-index-js" */),
  "component---src-pages-jobs-analista-index-js": () => import("../src/pages/Jobs/Analista/index.js" /* webpackChunkName: "component---src-pages-jobs-analista-index-js" */),
  "component---src-pages-jobs-backend-index-js": () => import("../src/pages/Jobs/Backend/index.js" /* webpackChunkName: "component---src-pages-jobs-backend-index-js" */),
  "component---src-pages-jobs-designer-index-js": () => import("../src/pages/Jobs/Designer/index.js" /* webpackChunkName: "component---src-pages-jobs-designer-index-js" */),
  "component---src-pages-jobs-frontend-index-js": () => import("../src/pages/Jobs/Frontend/index.js" /* webpackChunkName: "component---src-pages-jobs-frontend-index-js" */),
  "component---src-pages-jobs-index-js": () => import("../src/pages/Jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-pages-jobs-qa-index-js": () => import("../src/pages/Jobs/QA/index.js" /* webpackChunkName: "component---src-pages-jobs-qa-index-js" */),
  "component---src-pages-privacy-index-js": () => import("../src/pages/Privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-quotation-index-js": () => import("../src/pages/Quotation/index.js" /* webpackChunkName: "component---src-pages-quotation-index-js" */),
  "component---src-pages-signup-index-js": () => import("../src/pages/Signup/index.js" /* webpackChunkName: "component---src-pages-signup-index-js" */),
  "component---src-pages-terms-index-js": () => import("../src/pages/Terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-tracking-index-js": () => import("../src/pages/Tracking/index.js" /* webpackChunkName: "component---src-pages-tracking-index-js" */)
}

